<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary ">
                <v-toolbar-title class="mx-auto">{{
                  $t("Login")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <div>
                  <v-img class="mx-auto" style="height:140px;" src="/assets/logo.png"></v-img>
                  <!-- <h2 class="text-center mb-3 clr-primary">{{$t('VShape')}}</h2> -->
                </div>
                <v-form>
                
                  <div
                    class="py-0"
                    :class="{
                      'has-error': errors.has('addEditValidation.username'),
                    }"
                  >
                    <h4 class="mb-2">
                      {{ $t("UserName") }} <span class="required">*</span>
                    </h4>
                    <v-text-field
                      dense
                      class="mb-2"
                      outlined
                      name="username"
                      type="text"
                      v-validate="'required'"
                      data-vv-scope="addEditValidation"
                      :data-vv-as="$t('UserName')"
                      v-model="object.username"
                      hide-details
                    ></v-text-field>
                    <div
                      class="help-block"
                      v-if="errors.has('addEditValidation.username')"
                    >
                      {{ errors.first("addEditValidation.username") }}
                    </div>
                  </div>

                  <div
                    class="py-0"
                    :class="{
                      'has-error': errors.has('addEditValidation.password'),
                    }"
                  >
                    <h4 class="mb-2 mt-2">
                      {{ $t("Password") }} <span class="required">*</span>
                    </h4>
                    <v-text-field
                      dense
                      class="mb-2"
                      outlined
                      name="password"
                      type="password"
                      v-validate="'required'"
                      data-vv-scope="addEditValidation"
                      :data-vv-as="$t('Password')"
                      v-model="object.password"
                      hide-details
                       @keypress.enter.prevent="login"
                    ></v-text-field>
                    <div
                      class="help-block"
                      v-if="errors.has('addEditValidation.password')"
                    >
                      {{ errors.first("addEditValidation.password") }}
                    </div>
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions class="py-0 d-flex justify-center">
                <v-btn
                  class="mb-3"
                  small
                  outlined
                  width="160"
                  color="info"
                  @click="login"
                  >{{ $t("Login") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    object: {
      username: null,
      password: null,
    },
  }),
  methods: {
    login() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (valid) {
          this.$store.dispatch("auth/login", this.object).then((result) => {
            this.notifySuccess("Login", result);
            this.loginSuccess(result);
            let type = 'admin';
            // type= 'driver'
             window.location.href = `/#/${type}/home`;
          });
        } else {
          let result = {
            response: {
              data: {
                errors: this.$t("MissedData"),
              },
            },
          };
          this.notifyError("Login", result);
        }
      });
    },
  },
};
</script>
